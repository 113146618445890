<template>
  <el-upload class="upload-demo"
             :action="action"
             :data="{type:'FILE',...params}"
             :on-error="onError"
             :before-upload="beforeupload"
             :on-success="onSuccess"
             :show-file-list="false">
    <el-button :type="buttonType?buttonType:''"
               icon="el-icon-upload2">{{title?title:'导入文件'}}</el-button>
  </el-upload>
</template>
<script>
export default {
  props: ['action', 'title', 'params', 'buttonType'],
  data () {
    return {
      fileList: [],
      loading: null
    }
  },
  methods: {
    beforeupload () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    onError (e) {

    },
    onSuccess (e) {
      this.loading.close()
      this.$emit('on-success', e)
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
.upload-demo {
  display: inline-block;
}
</style>

<template>
  <el-dialog title="修改手机号码"
             width="30%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="手机号码"
                    prop="tel">
        <el-input v-model="form.tel"
                  placeholder="请输入手机号码"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">修改</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setPacketCoustomer } from '@/api/market'
export default {
  props: ['activity_id'],
  data () {
    return {
      form: {
        customer_id: '',
        tel: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    showModal (formData) {
      this.dialogVisible = true
      if (formData) {
        this.form = {
          customer_id: formData.id,
          tel: formData.tel
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setPacketCoustomer(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="container">
    <table-page dataName="customers"
                :search="search"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchPacketCoustomers">
      <template slot="button">
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportData">导出领取记录</el-button>
        <FileUpload action="/api/mall/admin/envelope/customer/with/yard/import"
                    class="mr-15"
                    @on-success="fileUploadSuccess"
                    v-permission="['ADMIN']"
                    v-if="$route.query.type === 'YARD'"
                    :params="{activity_id:$route.query.id}"
                    title="导入抽奖码" />
        <FileUpload action="/api/mall/admin/envelope/customer/import"
                    class="mr-15"
                    @on-success="fileSuccess"
                    v-permission="['ADMIN']"
                    v-else
                    :params="{activity_id:$route.query.id}"
                    title="导入客户" />
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-permission="['ADMIN']"
                   v-if="$route.query.type === 'YARD'"
                   @click="downloadYalTem">下载抽奖码导入模板</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-permission="['ADMIN']"
                   v-else
                   @click="downloadTelTem">下载导入客户模板</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="yard"
                       show-overflow-tooltip
                       label="抽奖码">
      </el-table-column>
      <el-table-column prop="merchant_name"
                       show-overflow-tooltip
                       label="派发金额">
        <template slot-scope="scope">
          <span>{{scope.row.money/100}}元</span>
        </template>
      </el-table-column>
      <el-table-column prop="web_status"
                       label="认证状态">
      </el-table-column>
      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="领取时间">
      </el-table-column>
      <el-table-column prop="wx_mini_openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope"
                  v-if="scope.row.status === 'PENDING'">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.coustomer.showModal(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     v-permission="['ADMIN']"
                     icon="el-icon-delete"
                     @click="delPacketCoustomer(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <Coustomer ref="coustomer"
               @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUpload'
import Coustomer from './components/SetTel'
import { fetchPacketCoustomers, delPacketCoustomer } from '@/api/market'
export default {
  name: 'Stores',
  data () {
    return {
      exportUrl: '',
      fetchPacketCoustomers,
      search: [{
        type: 'select',
        value: '',
        key: 'select_type',
        name: '搜索类型',
        list: [
          {
            value: 'TEL',
            label: '电话号码'
          }
        ]
      }, {
        type: 'input', value: '', key: 'tel', placeholder: '请输入关键字'
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'PENDING',
        label: '未领取'
      }, {
        value: 'RECEIVED',
        label: '已领取'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    Coustomer
  },
  methods: {
    fileUploadSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$alert('导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    fileSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$message.success('导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    downloadTelTem () {
      window.open('http://h.postpage.cn/data/envelope/%E7%BA%A2%E5%8C%85%E6%B4%BB%E5%8A%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '_blank')
    },
    downloadYalTem () {
      window.open('http://h.postpage.cn/data/envelope/%E7%BA%A2%E5%8C%85%E6%B4%BB%E5%8A%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF(%E6%8A%BD%E5%A5%96%E7%A0%81%E6%A8%A1%E5%BC%8F).xlsx', '_blank')
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    addStore () {
      this.$router.push({
        name: 'SetStore'
      })
    },
    setStore (id) {
      this.$router.push({
        name: 'SetStore',
        query: {
          id: id
        }
      })
    },
    delPacketCoustomer (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delPacketCoustomer({
          customer_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
